import '../scss/app.scss';

var swiper = new Swiper(".mySwiper", {
  pagination: {
    el: ".swiper-pagination",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
 
// Completely rewritten slider.js for fade effect instead of horizontal sliding
class SuccessStorySlider {
    constructor(elementSelector) {
        this.sliderElement = document.querySelector(elementSelector);
        if (!this.sliderElement) {
            console.error(`Slider element "${elementSelector}" not found.`);
            return;
        }
  
        this.container = this.sliderElement.querySelector('.success-story-slider-container');
        this.slider = this.sliderElement.querySelector('.success-story-slider');
        this.slides = this.sliderElement.querySelectorAll('.slide');
        this.prevButton = this.sliderElement.querySelectorAll('.success-story-slider-prev');
        this.nextButton = this.sliderElement.querySelectorAll('.success-story-slider-next');
  
        if (!this.slider || !this.slides.length) {
            console.error('Slider component is missing required inner elements (slider track or slides).');
            return;
        }
  
        this.currentIndex = 0;
        this.totalSlides = this.slides.length;
        this.transitioning = false;
  
        this.init();
    }
  
    init() {
        // Setup initial slide visibility
        this.setupSlides();
        
        // Add event listeners to all prev/next buttons
        this.prevButton.forEach(button => {
            button.addEventListener('click', () => this.prevSlide());
        });
        
        this.nextButton.forEach(button => {
            button.addEventListener('click', () => this.nextSlide());
        });
  
        // Optional: Add keyboard navigation
        document.addEventListener('keydown', (e) => {
            if (e.key === 'ArrowLeft') this.prevSlide();
            if (e.key === 'ArrowRight') this.nextSlide();
        });
        
        // Optional: Add swipe support
        this.setupSwipeSupport();
    }
    
    setupSlides() {
        // Hide all slides except the first one
        this.slides.forEach((slide, index) => {
            if (index === this.currentIndex) {
                slide.classList.add('active');
                slide.style.opacity = 1;
                slide.style.zIndex = 2;
            } else {
                slide.classList.remove('active');
                slide.style.opacity = 0;
                slide.style.zIndex = 1;
            }
        });
    }
  
    goToSlide(index) {
        if (this.transitioning) return;
        this.transitioning = true;
  
        if (index < 0) {
            index = this.totalSlides - 1;
        } else if (index >= this.totalSlides) {
            index = 0;
        }
  
        const currentSlide = this.slides[this.currentIndex];
        const nextSlide = this.slides[index];
  
        // Fade out current slide
        currentSlide.style.opacity = 0;
  
        setTimeout(() => {
            // After fade out completes
            currentSlide.classList.remove('active');
            currentSlide.style.zIndex = 1;
  
            // Setup next slide
            nextSlide.classList.add('active');
            nextSlide.style.opacity = 0;
            nextSlide.style.zIndex = 2;
  
            // Fade in next slide
            setTimeout(() => {
                nextSlide.style.opacity = 1;
  
                setTimeout(() => {
                    this.currentIndex = index;
                    this.transitioning = false;
                }, 500); // fade-in duration
            }, 50); // slight delay to trigger transition
        }, 500); // wait for fade-out to finish
    }
  
    nextSlide() {
        this.goToSlide(this.currentIndex + 1);
    }
  
    prevSlide() {
        this.goToSlide(this.currentIndex - 1);
    }
    
    setupSwipeSupport() {
        let touchStartX = 0;
        let touchEndX = 0;
        
        this.slider.addEventListener('touchstart', (e) => {
            touchStartX = e.changedTouches[0].screenX;
        }, { passive: true });
        
        this.slider.addEventListener('touchend', (e) => {
            touchEndX = e.changedTouches[0].screenX;
            handleSwipe();
        }, { passive: true });
        
        const handleSwipe = () => {
            const swipeThreshold = 50;
            if (touchStartX - touchEndX > swipeThreshold) {
                this.nextSlide(); // Swipe left
            } else if (touchEndX - touchStartX > swipeThreshold) {
                this.prevSlide(); // Swipe right
            }
        };
    }
  }
  
  // Initialize the slider when the DOM is ready
  document.addEventListener('DOMContentLoaded', () => {
    const slider = new SuccessStorySlider('.success-story-slider-grid');
  });